import { type ReactElement, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { SgwtMiniFooter } from '@sgwt/sgwt-widgets-react';
import { Header } from './Header.tsx';
import { Footer } from './Footer.tsx';
import { LoadingPage } from '../common/LoadingPage.tsx';

export const FullLayout = (): ReactElement => {

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(function () {
      setIsLoading(false);
  }, 600);
}, []);

  return (
    isLoading ? <LoadingPage/> : <div className="app">
        <Header />

        <main className="p-4 container-fluid">
        <Outlet />
        </main>

        <Footer />
        {/* <SgwtWebAnalytics siteId={matomo.siteId} baseUrl={matomo.url} debug={true} /> */}
      </div>
  );
};
import { type FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './applicationCard.css';
import {type EnvironmentClass, type ApplicationDto} from '@/models/services.ts'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';

type ApplicationCardProps = {
    service: any;
    readonly?: boolean;
    refreshData: () => void;
    toggleBookmarks: (e: any, application: ApplicationDto, currentStatus: boolean) => void;
    editSettings: (e: any, application: ApplicationDto) => void;
    showWarning: () => void;
};

const getStatusIcon = (status: string) =>  {
  return `card card-raising card-bordered m-2 mb-2 mt-2 w-100 card-${status === 'Healthy' ? 'healthy' : status === 'Unhealthy' ? 'unhealthy' :'degraded' }`;
}

  const DisplayEnvironments = (key: string, environments: EnvironmentClass[]) => {
    const OpenWindow = (e: any, url: string) => {
      window.open(url, '_blank').focus();
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    return environments?.map(({ name, status, statusPingdom, urls }) =>
      statusPingdom != null ? (

        <Col md="2" className='text-truncate form-label ' key={`env_${key}_${name}`}>{name}
          <div className='mt-2' style={{lineHeight:"25px"}}>          
              <span className={ `rounded-pill me-2 mb-2 ps-3 pe-3 pt-1 pb-1 text-color-white text-bg-${status == null ? 'danger' : status === 'Healthy' ? 'success' : status === 'Unhealthy' ? 'danger' : 'warning'}` }>
                  {' '}
                  {status ?? 'Unknow'}{' '}
              </span>
          </div>
          {statusPingdom != null ? 
            <div className='mt-2' style={{lineHeight:"25px"}}>          
              <span className={ `rounded-pill me-2 mb-2 ps-3 pe-3 pt-1 pb-1 text-color-white text-bg-${status == null ? 'danger' : status === 'Healthy' ? 'success' : status === 'Unhealthy' ? 'danger' : 'warning'}` }>
                  {' '}
                  {status ?? 'Unknow'}{' '}
              </span>
          </div>
          : null
          }
      </Col>
      ) : (
        <Col md="2" className='text-truncate form-label ' key={`env_${key}_${name}`}>{name}
          <div className='mt-2' style={{lineHeight:"25px"}}>          
              <span className={ `rounded-pill me-2 mb-2 ps-3 pe-3 pt-1 pb-1 text-color-white text-bg-${status == null ? 'danger' : status === 'Healthy' ? 'success' : status === 'Unhealthy' ? 'danger' : 'warning'}` }>
                  {' '}
                  {status ?? 'Unknow'}{' '}
              </span>
          </div>
      </Col>
      )
    );
  }

  const formatCasing = (str: string) => {
    if (str == null)
      return str;
    return str
      .replace(/(\B)[^ ]*/g, match => (match.toLowerCase()))
      .replace(/^[^ ]/g, match => (match.toUpperCase()));
  }

  const checkAccessibility = (e: any, isConfigured: boolean, func: any) => {
    if (!isConfigured) {
      func();
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    return true;
  }

const ApplicationCard: FC<ApplicationCardProps> = ({ service, refreshData, readonly, toggleBookmarks, editSettings, showWarning }: ApplicationCardProps) => (
  
    <Link className='no-text-link' onClick={(e) => checkAccessibility(e, service.isConfigured, showWarning) } to={readonly || (service.status != 'Healthy' && service.status != 'Unhealthy') ? '/' : `/healths/${service.key}`}>
      <Card className='card card-bordered' >
        <CardBody className='p-4'>
          <Row>
          <Col md="5">
              <div>
                  
                  {readonly ? <span className="material-icons-round" style={{width: "23px"}}></span> : <span className="material-icons-round" onClick={(e) => toggleBookmarks(e, service, service.isBookmarked)} title={service.isBookmarked ? "Remove bookmark":"Add to bookmark"}>{service.isBookmarked ? 'bookmark' : 'bookmark_outline'}</span> }
                  <h5 className='header-with-icon'>{service.name}</h5>
              </div>
              <Row className='ps-icon'>
                  <Col className='ps-3'>
                  <div><label className="form-label">Department</label></div>
                  <div>{service.managerDepartment}</div>
                  </Col>
                  <Col>
                      <div><label className="form-label">Hosting</label></div>
                      <div>{formatCasing(service.hosting)}</div>
                  </Col>
                  <Col>
                    <div style={{lineHeight:"25px", textAlign:"end"}}>Uptime:</div>
                    <div className='mt-2' style={{lineHeight:"25px", textAlign:"end", display: service.environments.filter((f) => f.statusPingdom != null).length > 0 ? "block" : "inline-block"}}>{service.environments.filter((f) => f.statusPingdom != null).length > 0 ? "Pingdom:": ""}</div>
                  </Col>
              </Row>
          </Col>
          <Col md="6">
              <Row>
                  {DisplayEnvironments(service.key, service.environments)}
              </Row>
          </Col>
          <Col md="1" className='text-end'>
          {readonly || (service.status != 'Healthy' && service.status != 'Unhealthy') ? null : <span className={`material-icons-round ${service.isConfigured ? "text-success" : "text-danger"}`} onClick={(e) => editSettings(e, service)} title="Settings">settings</span>}
          
          </Col>
          </Row>
       {service.status === 'Unhealthy' && service.partialIncident != null  && (
         <>
           <div>
             <span className="text-secondary">
               Partial incident: 
             </span>
           </div>
         </>
       )}
      </CardBody>
    </Card>
  </Link>
);

export default ApplicationCard;


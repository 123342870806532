import { useLayoutEffect, useRef, type FC, } from 'react';
import { type DataGraph } from '@/models/dataGraph.ts';
import { type Connection } from '@/models/connection.ts';
import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3'


export type OrgChartComponentProps = {
  data: DataGraph[],
  connections: Connection[],
  showConnection: boolean,
  layout: string,
  onNodeClick: (d: any) => void; 
};


const OrgChartComponent: FC<OrgChartComponentProps> = ({data, connections, showConnection, layout, onNodeClick}: OrgChartComponentProps) => {

  const d3Container = useRef(null);
  let chart: any = null;
  
  const ChangeView = (layout: string): void => { 
    chart.layout(layout).render().fit();
  }


    
  const filterChart = (e: any): void => {
    const value = e.currentTarget.value;
    chart.clearHighlighting();
    const data = chart.data();
    data.forEach((d) => {
      if (value != '' && d.name.toLowerCase().includes(value.toLowerCase())) {
        d._highlighted = true;
      }
    });
    chart.data(data).render().fit();
  }
  
    
  const updateWindow = (): void => {
      chart
      .svgWidth(window.innerWidth - 40)
      .svgHeight(window.innerHeight - 330)
          .render().fit();
    }
    window.onresize = updateWindow;
    // We need to manipulate DOM
    useLayoutEffect(() => {
      if (data && d3Container.current) {
        if (!chart) {
          chart = new OrgChart();
        }
        chart.nodeHeight((d: any) => {
          if (d.data.dataType?.indexOf('Azure') == 0) {
            return 100;
          }
          return 125 + 25
        })
        .nodeWidth((d: any) => 220 + 2)
        .svgWidth(window.innerWidth - 40)
        .svgHeight(window.innerHeight - 330)
        .childrenMargin((d) => 50)
        .compactMarginBetween((d) => 35)
        .compactMarginPair((d) => 30)
        .neighbourMargin((a, b) => 20)

        // .childrenMargin((d) => 50)
        // .compactMarginBetween((d) => 35)
        // .compactMarginPair((d) => 30)
        // .neighbourMargin((a, b) => 20)
  
        .linkUpdate(function(d: any, i: any, arr: any) {
          d3.select(this)
            .attr('stroke', d => d.data._upToTheRootHighlighted ? '#152785' : '#aaa')
  
            if (d.data._upToTheRootHighlighted) {
              d3.select(this).raise()
            }
        })
        .nodeContent(function (d: any, i: any, arr: any, state: any) {
          const color = '#FFFFFF';
          let imageDiffVert = 15 + 2;
          const links = d.data.links;
          

          let height = d.height;
          d.data.degraded = d.data.status === 'Degraded' ? true : false;
          d.data.unhealthy = d.data.status === 'Unhealthy' ? true : false;
          d.data.undefined = d.data.status === null || d.data.status === 'Unknow' || d.data.status === 'Undefined' ? true : false;
          
          let healthColor =  d.data.degraded ? '#FFE2C2' : d.data.unhealthy ? '#FFCECE' : d.data.undefined ? '#ddd' : '#DBF8DB';

          //chart.setUpToTheRootHighlighted(d.data.id).render().fit();
          if (d.data.dataType == 'Azure APIM LAN' || d.data.dataType?.indexOf('Azure') == 0) {
            height = 100;
            imageDiffVert = 15 + 2;
          }
          return d.data.dataType == 'Azure APIM LAN' ? `
          <div style='width:${
                    d.width
                  }px;height:${height}px;padding-top:${imageDiffVert - 2}px;padding-left:1px;padding-right:1px'>
                          <div style="font-family: 'Inter', sans-serif;margin-left:-1px;width:${d.width - 2}px;height:${height - imageDiffVert+7}px;border-radius:10px;; background-color: ${healthColor}; border: ${d.data.undefined? '1px solid #aaa' : d.data.degraded ? '3px solid #ff8400' : d.data.unhealthy ? '3px solid #ff3333' : '1px solid #228B22'}" >
                            <div style="position: absolute; top: 38px;background-color:${color};margin-top:${-imageDiffVert - 25}px;margin-left:${15}px;border-radius:100px;width:40px;height:40px;" ></div>
                            <div style="position: absolute;top: 12px;"><img src=" ${d.data.type}" style="    margin-top: -10px;margin-left:${20}px;border-radius:10px;width:30px;height:30px;" /></div>
                              <div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:25px">APIM LAN</div>
                              <div style="color:${d.data.degraded ? '#FF8C00' : d.data.unhealthy ? '#ff3333' : d.data.undefined? '#333' : '#228B22'};margin-left:20px;margin-top:3px;font-size:10px;font-weight:bold">${
                                d.data.undefined ? "" : d.data.status 
                              } </div>
                          </div>
                      </div>` : `
                  <div style='width:${
                    d.width
                  }px;height:${height}px;padding-top:${imageDiffVert - 2}px;padding-left:1px;padding-right:1px'>
                          <div style="font-family: 'Inter', sans-serif;margin-left:-1px;width:${d.width - 2}px;height:${height - imageDiffVert+7}px;border-radius:10px;; background-color: ${healthColor}; border: ${d.data.undefined? '1px solid #aaa' : d.data.degraded ? '3px solid #ff8400' : d.data.unhealthy ? '3px solid #ff3333' : '1px solid #228B22'}" >
                            <div style="position: absolute; top: 38px; background-color:${color};margin-top:${-imageDiffVert - 25}px;margin-left:${15}px;border-radius:100px;width:40px;height:40px;" ></div>
                            <div style="position: absolute;top: 12px;">
                              <img src=" ${d.data.type}" style="    margin-top: -10px;margin-left:${20}px;border-radius:10px;width:30px;height:30px;" />
                              ${d.data.problems != "" ? "<img src=\"/chart/warning.png\" style=\"margin-top: -10px;margin-left:${5}px;border-radius:10px;width:30px;height:30px;\" title=\""+d.data.problems+"\" />" :""}
                            </div>
                              <div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:25px" class="text-truncate" title="${d.data.name}">  ${
                                d.data.name
                              } </div>
                              <div style="color:${d.data.degraded ? '#FF8C00' : d.data.unhealthy ? '#ff3333' : '#228B22'};margin-left:20px;margin-top:3px;font-size:10px;font-weight:bold">${
                                d.data.undefined ? "" : d.data.status 
                              } </div>
                              <div style="color:#228B22;margin-left:20px;margin-top:3px;font-size:10px;font-weight:bold;line-height:30px;height:30px;">
                              ${d.data.checks?.map((check, index) => {
                                if (index < 7)
                                return `<img src="${check.icon}" style="margin-left:${10}px;border-radius:10px;width:30px;height:30px; display:'inline'}" title=${check.name} />`
                                if (index == 7)
                                  return '<span>... and more</span>'
                                }).join('')}
                              ${d.data.nodes?.map(node => {
                                return `<img src="/chart/node_${node}.svg" style="margin-left:${10}px;border-radius:10px;width:30px;height:30px; display:${node === 'undefined'? 'none':'inline'}" />`
                              }).join('')}
                              </div>
                              <div style="color:#228B22;margin-top:3px;font-size:10px;font-weight:bold">
                              <table style="border-top: 1px solid #E4E2E9; width: 100%; " >
                              <tr>
                                  ${links.map((item: any, index: any) => {
                                    return `<td><a href="${item.link}" target='_blank'><img src="/chart/${item.icon}" style="width:30px;height:30px;padding-top: 4px;" class="link" title="${item.name}" /></a></td>`
                                  }).join('')}
                              </tr>
                              </table>
                              </div>
  
                          </div>
                      </div>
                              `;
        })
          .container(d3Container.current)
          .data(data)
          .onNodeClick((d: any, i: any, arr: any) => {
            console.log(d, 'Id of clicked node ');
            onNodeClick(d);
          })
          .linkGroupArc(function linkValue({ source, target }) {
            const linkGenerationFunc = d3
              .linkHorizontal()
              .x((d: any) => d.x)
              .y((d: any) => d.y);
    
            return linkGenerationFunc({
              source: { x: source.x, y: source.y + 155 },
              target: { x: target.x, y: target.y - 135 },
            });
          })
          .expandAll().fit();
          if (showConnection)
          {
            let array: any = []
            connections.map((connection: Connection) => {
              array.push({'from': connection.from, 'to': connection.to, 'label':""});
            });
            chart.connections(array);
          }
          chart.render();
          window.chart = chart;
      }
    }, [data, d3Container.current, showConnection]);
  
    return (
      <div >
        <div ref={d3Container} />
      </div>
    );
};

export default OrgChartComponent;
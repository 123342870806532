import { type ReactElement } from 'react';
import { sgwtConnect } from '@/sgwt/sgwtConnect.ts';
import { ConnectedHome} from '../components/home/connectedHome-v2.tsx'
import { Home } from '../components/home/home-v2.tsx'

export function HomePage(): ReactElement {

  if (sgwtConnect.isAuthorized())
    return <ConnectedHome/>;
  return <Home />
}
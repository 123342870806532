import React, { type FC } from 'react';

interface Props {
}

const NoValue: FC<Props> = ({
}: Props) =>
    <section className="flex-center">
 
    <div className="empty-state">
      <i className="icon icon-lg">text_snippet</i>
      <h4>No data to display</h4>
      <p>To view data, apply criteria in the filters above</p>
    </div>
   
  </section>

export default NoValue;

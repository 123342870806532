import { type FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {type EnvironmentClass, type ApplicationDto} from '@/models/services.ts'
import { Nav, NavLink, NavItem, Row, Col, ButtonGroup, Button  } from 'reactstrap';

type MenuProps = {
    application: ApplicationDto;
    currentEnvironment: string;
    currentView: string;
    layout: string;
    useAll: boolean;
    changeEnvironment: (environment: string) => void;
    changeSearch: (term: string) => void;
    // toggleBookmarks: (e: any, application: ApplicationDto, currentStatus: boolean) => void;
    // editSettings: (e: any, application: ApplicationDto) => void;
    // showWarning: () => void;
};

const HealthMenu: FC<MenuProps> = ({ application, useAll, layout, currentEnvironment, currentView, changeEnvironment, changeSearch }: MenuProps) => 
{
    
    const navigate = useNavigate();
    const [showSearchText, setShowSearchText] = useState<string>('');
    
    useEffect(() => {
        setShowSearchText('');
    }, []);

    const formatCasing = (str: string) => {
        if (str == null)
          return str;
        return str
          .replace(/(\B)[^ ]*/g, match => (match.toLowerCase()))
          .replace(/^[^ ]/g, match => (match.toUpperCase()));
      }
      
    return (
    <>
    <Link to="/"><span className="material-icons-round" style={{ fontSize: "medium", verticalAlign: "text-bottom", marginRight: "5px" }}>arrow_back</span>Back</Link>
                <h2 className='mt-2'>{application?.name!}</h2>
                <Row className='mt-3 border-bottom-black'>
                    <Col md="3">
                        <div className='mb-2'><label className="form-label">Environments</label></div>
                        
                        <ButtonGroup>
                        {useAll ? 
                        <Button
                        onClick={() => {changeEnvironment('all'); } }
                        active={currentEnvironment === 'all'}  className={`btn btn-icon-height ${currentEnvironment == 'all' ? "btn-discreet-primary disabled2" : 'btn-outline-secondary' }`} key={`nav_${'all'}`} >{formatCasing('all')}</Button>
                        : null}
                        {application?.environments.map((environment) =>(
                            <Button
                            onClick={() => {changeEnvironment(environment.name);} }
                            active={currentEnvironment === environment.name}  className={`btn btn-icon-height ${currentEnvironment == environment.name ? "btn-discreet-primary disabled2" : 'btn-outline-secondary' }`} key={`nav_${environment.name}`} >{formatCasing(environment.name)}</Button>
                              //  {/* <NavLink as={Link} href={`/healths/${key}/${environment.name}`} key={environment.name} className={currentEnvironment == environment.name ? "active" : '' }>{formatCasing(environment.name)}</NavLink> */}
                        ))
                        }
                      </ButtonGroup>
                    </Col>
                    <Col md="1">
                    <div className='mb-2'><label className="form-label">View</label></div>
                    {currentView == "graph" ?
                    <span>
                        <button type="button" className="btn btn-outline-secondary" disabled><span className="material-icons-round">account_tree</span></button>
                        <button type="button" className="btn btn-outline-light" onClick={() => navigate("/table/"+application.key)}><span className="material-icons-round">view_list</span></button>
                    </span>
                    :
                    <span>
                        <button type="button" className="btn btn-outline-light" onClick={() => navigate("/healths/"+application.key)}><span className="material-icons-round">account_tree</span></button>
                        <button type="button" className="btn btn-outline-secondary" disabled><span className="material-icons-round">view_list</span></button>
                    </span>
                    }
                    </Col>
                    <Col md="4">
                    <div className='mb-2'><label className="form-label">Search</label></div>
                    <div className="input-group">
                    <div className="input-icon-start">
                        <em className="icon">search</em>
                    </div>
                    <input type="search" className="form-control" placeholder="Search for asset" onChange={(e) => { 
                        const value = e.currentTarget.value.toLowerCase();
                        setShowSearchText(value);
                        changeSearch(value) }} value={showSearchText} />
                    </div>
                    </Col>
                </Row>
    </>
)};

export default HealthMenu;


import type { ServiceGraphDto, ApplicationLink } from '@/models/healthGraph.ts';
import { type FC, type ReactNode, useEffect } from 'react';
import { useState } from 'react';
import { Modal, Button } from 'reactstrap';
import EditLinksModal from "./editLinksModal.tsx"
import { getUserConnection } from '@/sgwt/widgets.ts';
import { LinksService } from "@/services/LinksService.ts";
import './panelDetails.css'
import Loading from '../common/Loading.tsx';
import { AzureHealthService } from '@/services/AzureHealthService.ts'
import { type AzureHealthDto } from '@/models/azureHealth.ts'

interface Props {
    service: ServiceGraphDto | undefined;
    closePanel: () => void;
    refreshData: () => void;
}

const ButtonPill: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <span
      className="text-primary fw-standard badge rounded-pill bg-light bg-opacity-40 d-flex align-items-center justify-content-center m-2 px-3"
      style={{ height: 48 }}
    >
      {children}
    </span>
  );
};

const PanelDetails: FC<Props> = ({
  service,
  closePanel,
  refreshData,
}: Props) =>
  
{

  const [modalSettings, setModalSettings] = useState(false);
  const [canEditLinks, setCanEditLinks] = useState(false);
  const [azureHealth, setAzureHealth] = useState<AzureHealthDto|null>(null);
  const azureHealthService = new AzureHealthService();
  
  const EditLinks = () => {
    setModalSettings(!modalSettings);
  };
  const CloseModal = () => setModalSettings(!modalSettings);

  const SaveLinks = (newLinks: ApplicationLink[]) => {
    new LinksService().save(service!, newLinks);
    refreshData();
    CloseModal();
  };

  const GetIcon = (type: string) => {
    if (type === "web-apps") {
        return "/chart/cdn.png";
    }
    if (type === "website") {
        return "/chart/cdn.png";
    }
    if (type === "api") {
        return "/chart/api.png";
    }
    if (type === "database") {
        return "/chart/db.png";
    }
    if (type === "Azure Frontdoor") {
        return "/chart/frontdoor.svg";
    }
    if (type === "Azure APIM") {
        return "/chart/apim.svg";
    }
    if (type === "Azure APIM LAN") {
        return "/chart/apim.svg";
    }
    if (type === "Azure App Gateway") {
        return "/chart/appgateway.svg";
    }
    return "/chart/health.svg";
}
  
  useEffect(() => {
    let _canEditLinks = service?.apiDeclaration?.managerBackups.includes(getUserConnection()?.mail!) || service?.apiDeclaration?.manager ===  getUserConnection()?.mail;
    setCanEditLinks(_canEditLinks!);

    if (service?.type?.indexOf("Azure ") == 0) {
      if (service?.healthUri != null && service?.healthUri != '') {
        new AzureHealthService().get(service?.healthUri!).then((health: AzureHealthDto) => {
          setAzureHealth(health)
        });
      }
    }


  }, [service]);

  const formatAdditionnalPropertyName = (str) => {
    let result = str.replace(/([A-Z])/g, ' $1').trim();
    return result.charAt(0).toUpperCase() + result.slice(1);
    }


  return  service != null ? 
  <div className='panel-bordered-chart'>
    <div className='panel-bordered-chart-header'>
    <Button color="white float-end btn-icon btn-top" onClick={closePanel}>
        X
    </Button>
    <h4 className='text-truncate' title={service.name}>{service.name}</h4>
    </div>
    <h6 className='mt-5 text-primary fs-3'>Details:</h6>
    <div className='panel-bordered-chart-content'>
    {service.type == "web-apps" ? 
    <>
      <div className="row  mt-3">
        <div className="col-12">
            <label className="form-label mb-1" htmlFor="department">
              <span className="text-nowrap">Description:</span>
            </label>
            <div className="w-100" dangerouslySetInnerHTML={{__html: service.description!}}>
            </div>
        </div>
      </div>
    </> 
    :
    service.type.indexOf("Azure ") == 0 ? 
    <>
      <div className="row">
        <div className="col-12">
          {service.additionnals.map((additionnal) => (            
            <div className="row mt-3">
              <div className="col-12">
                <div className="form-group w-100 mb-0 mt-2">
                  <label className="form-label mb-1">
                    <span className="text-nowrap">{formatAdditionnalPropertyName(additionnal.name)}: </span>
                  </label>
                  <div className="w-100">
                    <span role="button">
                      {additionnal.value}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
                   
            <div className="row mt-3">
              <div className="col-12">
                <label className="form-label mb-1">
                  <h6 className='mt-5 text-primary fs-3'>Traffic:</h6>
                </label>
                <div className="w-100 mt-3">
                  <Loading isLoading={azureHealth == null}></Loading>
                  {azureHealth != null ?
                  <table className='table table-sm'>
                    <thead>
                      <tr>
                      <th>Status Code</th>
                      <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                        {azureHealth != null && service?.name?.indexOf('Frontdoor') == 0 ?
                        (
                          azureHealth.endpoint.responseHealth.map((health) => (
                              <tr>
                                <td>{health.httpResponseCode}</td>
                                <td>{health.count}</td>
                              </tr>
                            ))

                          )
                        :
                        azureHealth != null && service?.name?.indexOf('Application Gateway') == 0 ?
                        (
                          azureHealth.endpoint.applicationGateway.responseHealth.map((health) => (
                              <tr>
                                <td>{health.httpResponseCode}</td>
                                <td>{health.count}</td>
                              </tr>
                            ))

                          )
                        :
                        azureHealth != null && service?.name?.indexOf('APIM') == 0 ?
                        (
                          azureHealth.endpoint.applicationGateway.apim.responseHealth.map((health) => (
                              <tr>
                                <td>{health.httpResponseCode}</td>
                                <td>{health.count}</td>
                              </tr>
                            ))

                          )
                        :<tr></tr>
                        }
                    </tbody>
                  </table>
                  :<></>}
                  {azureHealth != null && service?.name?.indexOf('Frontdoor') == 0 ?
                      (
                        <>
                          <div>Requests Blocked by firewall count: {azureHealth.endpoint.firewall.totalBlockedRequest}</div>
                          <br/>
                        </>
                      ): null
                    }
                </div>
              </div>
            </div>

        </div>
      </div>
    </> 
    :
    <>
    <div className="row mt-3">
        <div className="col-12">
          <div className="form-group w-100 mb-0 mt-2">
            <label className="form-label mb-1" htmlFor="department">
              <span className="text-nowrap">Urls:</span>
            </label>
            <div className="w-100">
              <span role="button" id="department">
                <ul>
                {service.internetDeclaration?.expositionUrl != null ? <li><a href={service.internetDeclaration?.expositionUrl} target='_blank'>{service.internetDeclaration?.expositionUrl}</a></li> : null }
                {service.apiDeclaration?.accessPoint != 'none' ? <li><a href={service.apiDeclaration?.accessPoint} target='_blank'>{service.apiDeclaration?.accessPoint}</a></li> : null }
                {service.apiDeclaration?.baseEndPointUri != null ? <li><a href={service.apiDeclaration?.baseEndPointUri} target='_blank'>{service.apiDeclaration?.baseEndPointUri}</a></li> : null }
                </ul>
              </span>
            </div>
          </div>
        </div>
      </div>
    <div className="row mt-3">
        <div className="col-12">
          <div className="form-group w-100 mb-0 mt-2">
            <label className="form-label mb-1" htmlFor="department">
              <span className="text-nowrap">Manager department</span>
            </label>
            <div className="w-100">
              <span role="button" id="department">
                {service.apiDeclaration?.organizationalUnit}
              </span>
            </div>
          </div>
        </div>
      </div>
    <div className="row mt-3">
        <div className="col-12">
          <div className="form-group w-100 mb-0 mt-2">
            <label className="form-label mb-1" htmlFor="manager">
              <span className="text-nowrap">Manager</span>
            </label>
            <div className="w-100">
              <span role="button" id="manager">
              <a target='_blank' style={{display: "block"}} href={`https://org.sgmarkets.world.socgen/${service.apiDeclaration?.manager}`}>{service.apiDeclaration?.manager}</a>
              {service.apiDeclaration?.managerBackups.map(manager => <a target='_blank' style={{display: "block"}}  href={`https://org.sgmarkets.world.socgen/${manager}`}>{manager}</a>)}
              </span>
            </div>
          </div>
        </div>
      </div>
    <div className="row mt-3">
        <div className="col-12">
          <div className="form-group w-100 mb-0 mt-2">
            <label className="form-label mb-1" htmlFor="manager">
              <span className="text-nowrap">Support Contacts</span>
            </label>
            <div className="w-100">
              <span role="button" id="manager">
              {service.apiDeclaration?.supportContacts.map(contact => <div>{contact}</div>)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="application">
              <span className="text-nowrap">Application</span>
            </label>
            <div className="w-100">
              <span role="button" id="application">
                <a href={'https://developer.fr.world.socgen/explore/application/'+service?.apiDeclaration?.kearId} target='_blank'>{service?.apiDeclaration?.iAppliName}</a>
              </span>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="sginitiative">
              <span className="text-nowrap">SG Initiative</span>
            </label>
            <div className="w-100">
              <a href="/" id="sginitiative">
                Open project
              </a>
            </div>
          </div>
        </div>
      </div>

      
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="application">
              <span className="text-nowrap">Exposition</span>
            </label>
            <div className="w-100">
              {service?.apiDeclaration?.exposition}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="sginitiative">
              <span className="text-nowrap">Lifecycle</span>
            </label>
            <div className="w-100">
              {service?.apiDeclaration?.lifeCycle}
            </div>
          </div>
        </div>
      </div>
      
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="application">
              <span className="text-nowrap">RTO</span>
            </label>
            <div className="w-100">
              {service?.apiDeclaration?.formattedRto}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="sginitiative">
              <span className="text-nowrap">RPO</span>
            </label>
            <div className="w-100">
              {service?.apiDeclaration?.formattedRpo}
            </div>
          </div>
        </div>
      </div>


      <div className="mt-4 d-flex flex-wrap">
        <a  className='btn btn-light me-2 mb-2' href={`https://platformization.sgmarkets.world.socgen/explore/all?trigrams=${service?.apiDeclaration?.iAppliName}`} target='_blank'><span className="material-icons-round">open_in_new</span> Platformization</a>
        {service?.apiDeclaration?.sourceRepository == null ? null : 
          <a className='btn btn-light me-2 mb-2' href={`${service?.apiDeclaration?.sourceRepository}`} target='_blank'><span className="material-icons-round">open_in_new</span>Github</a>
        }
          <a className='btn btn-light me-2 mb-2' href={`https://developer.sgmarkets.com/explore/api/${service?.apiDeclaration?.key}/v1/information`} target='_blank'><span className="material-icons-round">open_in_new</span> Api</a>
        {service?.apiDeclaration?.swaggerFileUri != null ?
          <a className='btn btn-light me-2 mb-2' href={`${service?.apiDeclaration?.swaggerFileUri}`} target='_blank'><span className="material-icons-round">open_in_new</span> Swagger</a>
        : null
        }
      </div>
        <h6 className='mt-5 text-primary fs-3'>Checks:</h6>
        <div className="mt-4 d-flex flex-wrap">
          {service?.children?.map(_child =>{
                if (_child.type != "api" && _child.type != "SG APIM" && _child.type.indexOf('Azure') != 0) {
                  var _icon = GetIcon(_child.type).replace('.', `_${_child.status.toLowerCase()}.`);
                  return <>
                          <div className='w-100 mb-2'><img src={_icon} style={{ marginBottom: '0px', borderRadius: '10px', width: '30px', height: '30px', display: 'inline' }} title={_child.description} /><span className={_child.status ==='Healthy'? 'status-text-healthy': _child.status === 'Unhealthy' ? 'status-text-unhealthy' : 'status-text-degraded'}>{_child.name}</span>
                          <span>
                          {
                            _child.problem?.length > 0 ?  <span>: </span> : null
                          }  
                          {
                             _child.problem?.map((problem) => <span>{problem?.detail}</span>)
                          }
                          </span>
                          </div>
                          </>
                }
            })}
        </div>
      <h6 className='mt-5 text-primary fs-3'>Custom Links:</h6>
        <div className="mt-2 d-flex flex-wrap">
        {canEditLinks ?
        <Button className='btn btn-icon-start btn-info' onClick={() => EditLinks()}>
          <i className="icon icon-sm me-1">dataset</i>
          <span>Manage Custom Links</span>
        </Button>
        : <span className="fst-italic">You are not allowed to add custom links.</span> }
        </div>
      <h6 className='mt-5 text-primary fs-3'>Health:</h6>
        <div className="mt-4 d-flex flex-wrap">
          <div className='w-100'>Health Response Time: {service.healthCheckResponseTime != null ? service.healthCheckResponseTime : '-'}</div>
        </div>
        <div className="mt-2 d-flex flex-wrap">
          { service?.healthUri != '' ? <span>Url: <a target='_blank' href={service?.healthUri!}>{service.healthUri}</a></span> : <></>}
        </div>
      <h6 className='mt-5 text-primary fs-3'>Infrastructure:</h6>
      <div className="mt-4 d-flex flex-wrap">
        {service?.instances?.map(instance => {
          return instance.status == "Healthy" ? <div><span><i className="icon icon-md text-success">check_circle</i></span>{instance.name}</div> :
          instance.status == "Unhealthy" ? <div><span><i className="icon icon-md text-danger">check_circle</i></span>{instance.name}</div> : 
          <div><span><i className="icon icon-md text-warning">check_circle</i></span>{instance.name}</div>
        })}
      </div>
      </>}
      <Modal isOpen={modalSettings} size='xl'>
        <EditLinksModal service={service} closeModal={CloseModal} save={SaveLinks}/>
      </Modal>
      </div>
  </div>
  : null;
}
export default PanelDetails;
import { type ReactElement, type FC, useEffect, useState } from 'react';
import { type DataGraph, type GraphItem } from '@/models/dataGraph.ts'
import { type Instance, type ServiceGraphDto, type ApplicationLink } from '@/models/healthGraph.ts'
import OrgChartComponent from './healthOrgChart.tsx'
import { Row, Col  } from 'reactstrap';
import PanelDetails from './panelDetails.tsx';
import type { ApplicationDto } from '@/models/services.ts';
import { type Connection } from '@/models/connection.ts';
import { HealthService } from '@/services/HealthService.ts';
import { Console } from 'console';
import { quantize } from 'd3';


  
export type HealthTreeProps = {
    service: ServiceGraphDto,
    showConnection: boolean,
    refreshServiceData: () => void;
  };

  
const HealthTree: FC<HealthTreeProps>= ({
    service,
    showConnection,
    refreshServiceData,
}: HealthTreeProps) => {

    const [connections, setConnections] = useState<Connection[]>();
    const [data, setData] = useState<DataGraph[]>();
    const [dataGraph, setDataGraph] = useState<DataGraph | null>(null);

    

    const ParseChild = (parentId: string, array: DataGraph[], child: ServiceGraphDto, alreadyAddedName: string[], connections: Connection[], ChangeView) => {
        const item = {} as DataGraph;

        item.id = child.key;
        item.key = child.key;
        item.parentId = parentId;
        item.name = child.name;
        item.status = child.status;
        item.service = child;

         if (child.healthUri != null) {
             item.healthUri = child.healthUri;
        }


        item.type = GetType(child.type);
        item.dataType = child.type;
        item.problems = child.problem != null && child.problem?.length > 0 ? child.problem.map((t) => t.detail).join(",") : "";
        item.links = [];
        item.checks = [];
        child.childrenLinks?.map((link: string) => {
            connections.push({ from: item.id, to: link});
        });
        child.applicationLinks?.map((link: ApplicationLink) => {
            item.links.push({ name: link.type, icon: getLinkIcon(link.type), link: link.url });
        });
        item.nodes= [];
        child.instances?.map((instance: Instance)  => {
            item.nodes?.push(instance.status);
        });
        array.push(item);

        child.children?.map(_child =>{
            if (_child.type != "api" && _child.type != "SG APIM" && _child.type.indexOf('Azure') != 0) {
                _child.icon = GetType(_child.type).replace('.', `_${_child.status.toLowerCase()}.`)
                item.checks?.push(_child);
            }
            else {
                ParseChild(item.id, array, _child, alreadyAddedName, connections);
            }
        });

    }

    const ComputeDataGraph = (service: ServiceGraphDto) => {

        let alreadyAddedName: string[] = [];
        let connections: Connection[] = [];
        const array: DataGraph[] = [];
        const graphItems: GraphItem[] = [];
        const item = {} as DataGraph;
        if (service != null) {
            item.id = service.key;
            alreadyAddedName.push(service.name);
            item.key = service.key;
            item.name = service.name;
            item.status = service.status;
            item.service = service;
            item.problems = service.problem != null && service.problem?.length > 0 ? service.problem.map((t) => t.detail).join(","): "";
            item.type = GetType(service.type);
            item.links = [];
            item.nodes= [];
            item.checks = [];
            service.applicationLinks?.map((link: ApplicationLink) => {
                item.links.push({ name: link.type, icon: getLinkIcon(link.type), link: link.url });
            });
            array.push(item);

            service.children?.map((child: ServiceGraphDto) =>{
                ParseChild(item.id, array, child, alreadyAddedName, connections);
            });
            setData(array);
            setConnections(connections);
        }
    }

    const GetType = (type: string) => {
        if (type === "web-apps") {
            return "/chart/cdn.png";
        }
        if (type === "website") {
            return "/chart/cdn.png";
        }
        if (type === "api") {
            return "/chart/api.png";
        }
        if (type === "database") {
            return "/chart/db.png";
        }
        if (type === "Azure Frontdoor") {
            return "/chart/frontdoor.svg";
        }
        if (type === "Azure APIM") {
            return "/chart/apim.svg";
        }
        if (type === "Azure APIM LAN") {
            return "/chart/apim.svg";
        }
        if (type === "Azure App Gateway") {
            return "/chart/appgateway.svg";
        }
        return "/chart/health.svg";
    }

    const getLinkIcon = (type: string) => {
        switch(type) {
            case 'do-it-now':
                return "din.png";
            default:
                return type+".svg"
        }
    } 
    
    const onNodeClick = (d: any) => {
        setDataGraph(d.data);
    }
    const closePanel = (): void => {
        setDataGraph(null);
    }
    const refreshData = (): void => {
        refreshServiceData();
    }

    useEffect(() => {
        ComputeDataGraph(service);
    }, [service]);


    return (
        <>
        <PanelDetails service={dataGraph?.service} closePanel={closePanel} refreshData={refreshData} />
        {data != null ? <OrgChartComponent data={data} onNodeClick={onNodeClick} connections={connections!} showConnection={showConnection} /> : null }
        </>
  );
};

export default HealthTree;

